import React, { useEffect, useState } from 'react'
import { TfiClose } from 'react-icons/tfi'
// 
import '../../STYLESHEETS/Navigation.css'
// 
import logo from '../../PHOTOS/stock.png'
// import { Link } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';
import { c_businessName } from '../../Constants'
import { useNavigate } from 'react-router';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

export default function Navigation() {
    const navigate = useNavigate()
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }

    const [showMenuOptions, setShowMenuOptions] = useState(false)

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='nav-body bg2'>
            <div className='nav-top'>
                <img onClick={() => { navigate('/') }} src={logo} />
                <TfiClose className='nav-icon color1' onClick={closeNav} />
            </div>
            <div className='nav-links font1'>
                <Link className='nav-link color1 all-caps' to="/about">About</Link>
                <Link className='nav-link color1 all-caps' to="/gallery">Gallery</Link>
                <div className="separate">
                    <Link className='nav-link color1 all-caps' to="/menu">Menu</Link>
                    {showMenuOptions ? <BsChevronUp onClick={() => { setShowMenuOptions(false) }} className='nav-option-icon' /> : <BsChevronDown onClick={() => { setShowMenuOptions(true) }} className='nav-option-icon' />}
                </div>
                {
                    showMenuOptions ?
                        <div className='nav-options'>
                            <Link className='nav-option-link color1 all-caps' to="/menu#Lunch Hour - 11 AM - 2:30 PM">Lunch Hour</Link>
                            <Link className='nav-option-link color1 all-caps' to="/menu#Happy Hour - 4 PM - 6 PM">Happy Hour</Link>
                            <Link className='nav-option-link color1 all-caps' to="/menu#Appetizers">Appetizers</Link>
                            <Link className='nav-option-link color1 all-caps' to="/menu#Sashimi Appetizers">Sashimi Appetizers</Link>
                            <Link className='nav-option-link color1 all-caps' to="/menu#Salads">Salads</Link>
                            <Link className='nav-option-link color4 all-caps' to="/menu#House Special Rolls">House Special Rolls</Link>

                            <Link className='nav-option-link color1 all-caps' to="/menu#Nigiri (2 pc)">Nigiri</Link>
                            <Link className='nav-option-link color1 all-caps' to="/menu#Sashimi">Sashimi</Link>
                            <Link className='nav-option-link color1 all-caps' to="/menu#Ramen & Udon">Ramen & Udon</Link>
                            <Link className='nav-option-link color1 all-caps' to="/menu#Sushi & Sashimi Entrees">Sushi & Sashimi Entrees</Link>
                            <Link className='nav-option-link color1 all-caps' to="/menu#Main Entrees (Comes with Miso and House Salad)">Main Entrees</Link>
                            <Link className='nav-option-link color1 all-caps' to="/menu#Poke (Comes with Miso)">Poke</Link>
                            <Link className='nav-option-link color1 all-caps' to="/menu#Beginner Rolls">Beginner Rolls</Link>
                            <Link className='nav-option-link color1 all-caps green' to="/menu#Vegetarian Rolls">Vegetarian Rolls</Link>
                            <Link className='nav-option-link color1 all-caps' to="/menu#Wine">Drinks</Link>

                        </div> : <div></div>
                }

                <Link className='nav-link color1 all-caps' to="/blog">Blog</Link>
                <Link className='nav-link color1 all-caps' to="/contact">Contact Us</Link>
            </div>
            <p className='copy font1 color1'>&copy; {c_businessName} 2023. All Rights Reserved.</p>
        </div>
    )
}
