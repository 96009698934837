import React, { useEffect } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import '../STYLESHEETS/Gallery.css'
import logo from '../PHOTOS/stock.png'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { Link, useNavigate } from 'react-router-dom'
// 
import img1 from '../PHOTOS/MAIN/May52023/sushi1.jpg'
import img2 from '../PHOTOS/MAIN/May52023/sushi2.jpg'
import img3 from '../PHOTOS/MAIN/May52023/sushi3.jpg'
import img4 from '../PHOTOS/MAIN/May52023/sushi4.jpg'
import img5 from '../PHOTOS/MAIN/May52023/sushi5.jpg'
import img6 from '../PHOTOS/MAIN/May52023/sushi6.jpg'
import img7 from '../PHOTOS/MAIN/May52023/sushi7.jpg'
import img8 from '../PHOTOS/MAIN/May52023/sushi8.jpg'
import img9 from '../PHOTOS/MAIN/May52023/sushi9.jpg'
import img10 from '../PHOTOS/MAIN/May52023/sushi10.jpg'
import img22 from '../PHOTOS/MAIN/sushi21.jpg'
import img12 from '../PHOTOS/MAIN/sushi22.jpg'
import img13 from '../PHOTOS/MAIN/sushi23.jpg'
import img14 from '../PHOTOS/MAIN/sushi24.jpg'
import img15 from '../PHOTOS/MAIN/sushi25.jpg'
import img16 from '../PHOTOS/MAIN/sushi26.jpg'
import img17 from '../PHOTOS/MAIN/sushi27.jpg'
import img18 from '../PHOTOS/MAIN/sushi28.jpg'
import img19 from '../PHOTOS/MAIN/sushi29.jpg'
import img20 from '../PHOTOS/MAIN/sushi30.jpg'
import img21 from '../PHOTOS/MAIN/sushi31.jpg'
import img11 from '../PHOTOS/MAIN/sushi32.jpg'

import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { Helmet } from 'react-helmet'
import { c_helmet, c_mainURL } from '../Constants'
import { useDispatch } from 'react-redux'
import { setPhotoState } from '../REDUX/SLICES/PhotoSlice'

export default function Gallery() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }

    const images = [
        {
            Img: img1,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img2,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img3,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img4,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img5,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img6,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img7,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img8,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img9,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img10,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img11,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img12,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img13,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img14,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img15,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img16,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img17,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img18,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img19,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img20,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img21,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        {
            Img: img22,
            Alt: "This is the alt attribute for this image.",
            Lens: "50mm",
            Title: "Gloomy",
            Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        },
        // {
        //     Img: img1,
        //     Alt: "This is the alt attribute for this image.",
        //     Lens: "50mm",
        //     Title: "Gloomy",
        //     Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        // },
        // {
        //     Img: img2,
        //     Alt: "This is the alt attribute for this image.",
        //     Lens: "50mm",
        //     Title: "Gloomy",
        //     Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        // },
        // {
        //     Img: img3,
        //     Alt: "This is the alt attribute for this image.",
        //     Lens: "50mm",
        //     Title: "Gloomy",
        //     Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        // },
        // {
        //     Img: img4,
        //     Alt: "This is the alt attribute for this image.",
        //     Lens: "50mm",
        //     Title: "Gloomy",
        //     Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        // },
        // {
        //     Img: img5,
        //     Alt: "This is the alt attribute for this image.",
        //     Lens: "50mm",
        //     Title: "Gloomy",
        //     Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        // },
        // {
        //     Img: img6,
        //     Alt: "This is the alt attribute for this image.",
        //     Lens: "50mm",
        //     Title: "Gloomy",
        //     Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        // },
        // {
        //     Img: img7,
        //     Alt: "This is the alt attribute for this image.",
        //     Lens: "50mm",
        //     Title: "Gloomy",
        //     Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        // },
        // {
        //     Img: img8,
        //     Alt: "This is the alt attribute for this image.",
        //     Lens: "50mm",
        //     Title: "Gloomy",
        //     Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        // },
        // {
        //     Img: img9,
        //     Alt: "This is the alt attribute for this image.",
        //     Lens: "50mm",
        //     Title: "Gloomy",
        //     Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        // },
        // {
        //     Img: img10,
        //     Alt: "This is the alt attribute for this image.",
        //     Lens: "50mm",
        //     Title: "Gloomy",
        //     Desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan."
        // }
    ]


    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "Gallery", Views: 0 })
    }, [])
    return (
        <div className='main'>
            {c_helmet("Gallery")}
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="gallery font1">
                <h1 className='page-title color4 all-caps'>A closer look</h1>
                <br/>
                <div className='gallery-wrap'>
                    {
                        images.map((img, i) => {
                            return (
                                <img key={i} src={img.Img} alt={`${img.Alt}`} className="gallery-img" onClick={() => {
                                    dispatch(setPhotoState(img));
                                    navigate('/gallery-photo')
                                }} />
                            )
                        })
                    }
                </div>
            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
            <style>
            </style>
        </div>
    )
}
