import React, { useEffect } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import '../STYLESHEETS/About.css'
import logo from '../PHOTOS/stock.png'
import img1 from '../PHOTOS/MAIN/sushi19.jpg'
import img2 from '../PHOTOS/MAIN/sushi29.jpg'
import img3 from '../PHOTOS/MAIN/sushi27.jpg'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { Link } from 'react-router-dom'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { Helmet } from 'react-helmet'
import { c_helmet, c_mainURL } from '../Constants'

export default function About() {
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "About", Views: 0 })
    }, [])
    return (
        <div className='main'>
            {c_helmet("About")}
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="about font1">
                <h1 className='page-title color4'>About Us</h1>
                <div className='about-wrap'>
                    <div className='about-section'>
                        <div>
                            <h2 className='about-sub font2'>Delicious Sushi and Japanese Delicacies in Greenwood Village at Asa Sushi</h2>
                            <img src={img1} className="about-img" />
                        </div>
                        <div className='about-text font2'>
                            <p>
                                Asa Sushi is a trendy sushi restaurant located in the bustling area of Greenwood Village. Our commitment to using only the freshest and highest-quality ingredients ensures that every bite of our sushi rolls, sashimi, and other Japanese delicacies is bursting with flavor.</p>
                            <p>
                                Whether you're a sushi lover or new to the cuisine, our extensive menu has something for everyone. From classic rolls like the California and spicy tuna to creative options like the rainbow and dragon rolls, there is no shortage of delicious options to satisfy your cravings.
                            </p>
                        </div>
                    </div>
                    <div className='about-section font2'>
                    <div className='about-text'>
                            <p>
                                One of the highlights of Asa Sushi is our selection of specialty desserts that are sure to delight your taste buds. Our skilled pastry chefs create delectable treats that are perfect for finishing off your meal. From our popular green tea ice cream to our mochi ice cream, our dessert menu is full of unique and delicious options that are perfect for any sweet tooth. And with our casual and inviting atmosphere, Asa Sushi is the perfect place to relax with friends and family while enjoying your favorite sushi and dessert.
                            </p>
                        </div>
                        <div>
                            <h2 className='about-sub'>Satisfy Your Sweet Tooth with Specialty Desserts at Asa Sushi"</h2>
                            <img src={img2} className="about-img" />
                        </div>
                        
                    </div>
                    <div className='about-section font2'>
                        <div>
                            <h2 className='about-sub'>Convenient Takeout Options Available at Asa Sushi in Greenwood Village</h2>
                            <img src={img3} className="about-img" />
                        </div>
                        <div className='about-text'>
                            <p>
                                In addition to our dine-in experience, we also offer convenient takeout options for those who prefer to enjoy their sushi in the comfort of their own home. Simply give us a call to learn more about our takeout menu and place your order. Whether you're stopping in for a quick bite or picking up sushi to go, Asa Sushi is dedicated to providing exceptional service and delicious food. Come visit us today and taste the best sushi in Greenwood Village!
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
