import React, { useEffect, useState } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
// 
import '../STYLESHEETS/Menu.css'
import logo from '../PHOTOS/stock.png'
import img1 from '../PHOTOS/MAIN/sushi17.jpeg'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { Link } from 'react-router-dom'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { c_helmet, c_mainURL } from '../Constants'
import { Helmet } from 'react-helmet'

export default function Menu() {
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }

    const menu = [
        {
            id: 109,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Gyoza",
            Desc: ""
        },
        {
            id: 110,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Shumai",
            Desc: ""
        },
        {
            id: 111,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Edamame",
            Desc: ""
        },
        {
            id: 112,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "3" }],
            Name: "Miso Soup",
            Desc: ""
        },
        {
            id: 113,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "12" }],
            Name: "2 Beginner Rolls",
            Desc: ""
        },
        {
            id: 114,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "22" }],
            Name: "2 Special Rolls",
            Desc: "Limited to certain rolls"
        },
        {
            id: 115,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Sushi Sampler",
            Desc: "4 Nigiri, 4pc Cali, Miso, Salad"
        },
        {
            id: 116,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "15" }],
            Name: "Sushi Sashimi",
            Desc: "4 Nigiri, 3 Sashimi, Miso"
        },
        {
            id: 117,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "19" }],
            Name: "Chirashi",
            Desc: "Miso"
        },
        {
            id: 118,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "15" }],
            Name: "Salmon Salad",
            Desc: ""
        },
        {
            id: 119,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "12" }],
            Name: "Chicken Yakisoba",
            Desc: ""
        },
        {
            id: 120,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "12" }],
            Name: "Beef Yakisoba",
            Desc: ""
        },
        {
            id: 121,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "15" }],
            Name: "Korean Spicy Pork",
            Desc: ""
        },
        {
            id: 122,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "12" }],
            Name: "Chicken Teriyaki",
            Desc: ""
        },
        {
            id: 123,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "12" }],
            Name: "Beef Teriyaki",
            Desc: ""
        },
        {
            id: 124,
            Category: "Lunch Hour - 11 AM - 2:30 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "13" }],
            Name: "Tempura Udon",
            Desc: ""
        },
        {
            id: 125,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Gyoza",
            Desc: ""
        },
        {
            id: 126,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Shumai",
            Desc: ""
        },
        {
            id: 127,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "4" }],
            Name: "Edamame",
            Desc: ""
        },
        {
            id: 128,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Monkey Brain",
            Desc: ""
        },
        {
            id: 129,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "3" }],
            Name: "Miso Soup",
            Desc: ""
        },
        {
            id: 130,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Steamed Buns",
            Desc: ""
        },
        {
            id: 131,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Chicken Karage",
            Desc: ""
        },
        {
            id: 132,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "15" }],
            Name: "Sushi Sampler",
            Desc: ""
        },
        {
            id: 133,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "4" }],
            Name: "Avocado Roll",
            Desc: ""
        },
        {
            id: 134,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "4" }],
            Name: "Cucumber Roll",
            Desc: ""
        },
        {
            id: 135,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Spicy Tuna",
            Desc: ""
        },
        {
            id: 136,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "5" }],
            Name: "California Roll",
            Desc: ""
        },
        {
            id: 137,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Crab Crunch Roll",
            Desc: ""
        },
        {
            id: 138,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Rainbow Roll",
            Desc: ""
        },
        {
            id: 139,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Tokyo Roll",
            Desc: ""
        },
        {
            id: 140,
            Category: "Happy Hour - 4 PM - 6 PM (Mon - Fri)",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Crab Lover",
            Desc: ""
        },
        {
            id: 141,
            Category: "Happy Hour Drinks",
            Prices: [{ Size: "", Price: "5.5" }],
            Name: "Large Sake",
            Desc: ""
        },
        {
            id: 142,
            Category: "Happy Hour Drinks",
            Prices: [{ Size: "", Price: "5.5" }],
            Name: "BOGO Small Sake",
            Desc: ""
        },
        {
            id: 143,
            Category: "Happy Hour Drinks",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Large Beer",
            Desc: ""
        },
        {
            id: 144,
            Category: "Happy Hour Drinks",
            Prices: [{ Size: "", Price: "4" }],
            Name: "Small Beer",
            Desc: ""
        },
        {
            id: 145,
            Category: "Happy Hour Drinks",
            Prices: [{ Size: "", Price: "11" }],
            Name: "Sake Bomb",
            Desc: ""
        },
        {
            id: 146,
            Category: "Happy Hour Drinks",
            Prices: [{ Size: "", Price: "5.5" }],
            Name: "House Wine",
            Desc: ""
        },
        {
            id: 0,
            Category: "Appetizers",
            Prices: [{ Size: "", Price: "4" }],
            Name: "Miso Soup",
            Desc: ""
        },
        {
            id: 1,
            Category: "Appetizers",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Edamame",
            Desc: ""
        },
        {
            id: 2,
            Category: "Appetizers",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Angry Edamame",
            Desc: ""
        },
        {
            id: 3,
            Category: "Appetizers",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Shumai (5 pc)",
            Desc: ""
        },
        {
            id: 4,
            Category: "Appetizers",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Chicken Karage",
            Desc: ""
        },
        {
            id: 5,
            Category: "Appetizers",
            Prices: [{ Size: "", Price: "8" }],
            Name: "Gyoza (5 pc)",
            Desc: ""
        },
        {
            id: 6,
            Category: "Appetizers",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Steamed Buns",
            Desc: ""
        },
        {
            id: 7,
            Category: "Appetizers",
            Prices: [{ Size: "", Price: "8" }],
            Name: "Green Mussels (5 pc)",
            Desc: ""
        },
        {
            id: 8,
            Category: "Appetizers",
            Prices: [{ Size: "", Price: "9" }],
            Name: "Calamari",
            Desc: ""
        },
        {
            id: 9,
            Category: "Appetizers",
            Prices: [{ Size: "", Price: "9" }],
            Name: "Shrimp & Veggie Tempura",
            Desc: ""
        },
        {
            id: 10,
            Category: "Appetizers",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Shrimp Tempura (4 pc)",
            Desc: ""
        },
        {
            id: 11,
            Category: "Appetizers",
            Prices: [{ Size: "", Price: "8" }],
            Name: "Monkey Brain",
            Desc: ""
        },
        {
            id: 12,
            Category: "Appetizers",
            Prices: [{ Size: "", Price: "8" }],
            Name: "Heart Attack",
            Desc: ""
        },
        {
            id: 13,
            Category: "Sashimi Appetizers",
            Prices: [{ Size: "", Price: "15" }],
            Name: "Hamachi (Yellowtail)",
            Desc: ""
        },
        {
            id: 14,
            Category: "Sashimi Appetizers",
            Prices: [{ Size: "", Price: "15" }],
            Name: "Maguro (Tuna)",
            Desc:
                ""
        },
        {
            id: 15,
            Category: "Sashimi Appetizers",
            Prices: [{ Size: "", Price: "15" }],
            Name: "Shake (Salmon)",
            Desc: ""
        },
        {
            id: 16,
            Category: "Sashimi Appetizers",
            Prices: [{ Size: "", Price: "15" }],
            Name: "Shiro Maguro Tataki (Seared Albacore)",
            Desc: ""
        },
        {
            id: 17,
            Category: "Sashimi Appetizers",
            Prices: [{ Size: "", Price: "15" }],
            Name: "Maguro Tataki (Seared Tuna)",
            Desc: ""
        },
        {
            id: 18,
            Category: "Salads",
            Prices: [{ Size: "", Price: "4" }],
            Name: "Cucumber Salad (Sweet Mayo)",
            Desc: ""
        },
        {
            id: 19,
            Category: "Salads",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Seaweed Salad (Ponzu Sauce)",
            Desc: ""
        },
        {
            id: 20,
            Category: "Salads",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Avocado Salad (Sweet Mayo)",
            Desc: ""
        },
        {
            id: 21,
            Category: "Salads",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Crab Salad (Spicy Mayo)",
            Desc: ""
        },
        {
            id: 22,
            Category: "Salads",
            Prices: [{ Size: "", Price: "8" }],
            Name: "Sunomono (Japanese Cucumber Salad w/ Fish)",
            Desc: ""
        },
        {
            id: 23,
            Category: "Salads",
            Prices: [{ Size: "", Price: "5" }],
            Name: "House Salad (Ginger Dressing)",
            Desc: ""
        },
        {
            id: 24,
            Category: "Salads",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Squid Salad",
            Desc: ""
        },
        {
            id: 25,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Maguro (Tuna) GF*",
            Desc: ""
        },
        {
            id: 26,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Maguro Tataki (Seared Tuna) GF*",
            Desc: ""
        },
        {
            id: 27,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Shake (Scottish Salmon) GF*",
            Desc: ""
        },
        {
            id: 28,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Hamachi (Yellowtail) GF*",
            Desc: ""
        },
        {
            id: 29,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Tai (Red Snapper) GF*",
            Desc: ""
        },
        {
            id: 30,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Shiro Maguro (Albacore) GF*",
            Desc: ""
        },
        {
            id: 31,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Walu (White Tuna) GF*",
            Desc: ""
        },
        {
            id: 32,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Smoked Salmon GF*",
            Desc: ""
        },
        {
            id: 33,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Saba (Mackerel) GF*",
            Desc: ""
        },
        {
            id: 34,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Tako (Octopus) GF*",
            Desc: ""
        },
        {
            id: 35,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Ebi (Shrimp) GF*",
            Desc: ""
        },
        {
            id: 36,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "9" }],
            Name: "Hotate (Scallops) GF*",
            Desc: ""
        },
        {
            id: 37,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Ika (Squid) GF*",
            Desc: ""
        },
        {
            id: 38,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Unagi (Eel)",
            Desc: ""
        },
        {
            id: 39,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "4" }],
            Name: "Tamago (Egg)",
            Desc: ""
        },
        {
            id: 40,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Hokkigai (Clam) GF*",
            Desc: ""
        },
        {
            id: 41,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Kani (Crab Stick) GF*",
            Desc: ""
        },
        {
            id: 42,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Tobiko (Flying Fish Row) GF*",
            Desc: ""
        },
        {
            id: 43,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Masago (Smelt Fish Roe) GF*",
            Desc: ""
        },
        {
            id: 44,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Ikura (Salmon Roe) GF*",
            Desc: ""
        },
        {
            id: 45,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "16" }],
            Name: "O'toro (Fatty Tuna) GF*",
            Desc: ""
        },
        {
            id: 46,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "12" }],
            Name: "Uni (Sea Urchin) GF*",
            Desc: ""
        },
        {
            id: 47,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "12" }],
            Name: "Amaebi (Sweet Shrimp) GF*",
            Desc: ""
        },
        {
            id: 48,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Aji (Spanish Mackerel) GF*",
            Desc: ""
        },
        {
            id: 49,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Snow Crab Legs GF*",
            Desc: ""
        },
        {
            id: 50,
            Category: "Nigiri (2 pc)",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Inari (Tofu) GF*",
            Desc: ""
        },
        {
            id: 51,
            Category: "Sashimi",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Shake (Scottish Salmon) GF*",
            Desc: ""
        },
        {
            id: 52,
            Category: "Sashimi",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Hamachi (Yellowtail) GF*",
            Desc: ""
        },
        {
            id: 53,
            Category: "Sashimi",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Maguro (Tuna) GF*",
            Desc: ""
        },
        {
            id: 54,
            Category: "Sashimi",
            Prices: [{ Size: "", Price: "20" }],
            Name: "O'Toro (Fatty Tuna) GF*",
            Desc: ""
        },
        {
            id: 55,
            Category: "Sashimi",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Walu (White Tuna) GF*",
            Desc: ""
        },
        {
            id: 56,
            Category: "Sashimi",
            Prices: [{ Size: "", Price: "9" }],
            Name: "Maguro Tataki (Seared Tuna) GF*",
            Desc: ""
        },
        {
            id: 57,
            Category: "Sashimi",
            Prices: [{ Size: "", Price: "9" }],
            Name: "Unagi (Eel)",
            Desc: ""
        },
        {
            id: 58,
            Category: "Sashimi",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Japanese Tai (Red Snapper) GF*",
            Desc: ""
        },
        {
            id: 59,
            Category: "Sashimi",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Smoked Salmon GF*",
            Desc: ""
        },
        {
            id: 60,
            Category: "Sashimi",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Shiro Maguro (Albacore) GF*",
            Desc: ""
        },
        {
            id: 61,
            Category: "Sashimi",
            Prices: [{ Size: "", Price: "8" }],
            Name: "Saba (Mackerel) GF*",
            Desc: ""
        },
        {
            id: 62,
            Category: "Sashimi",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Tako (Octopus) GF*",
            Desc: ""
        },
        {
            id: 63,
            Category: "Ramen & Udon",
            Prices: [{ Size: "", Price: "15" }],
            Name: "Tonkotsu (Pork Bone Broth)",
            Desc: ""
        },
        {
            id: 64,
            Category: "Ramen & Udon",
            Prices: [{ Size: "", Price: "15" }],
            Name: "Miso (Soy Bean Broth)",
            Desc: ""
        },
        {
            id: 65,
            Category: "Ramen & Udon",
            Prices: [{ Size: "", Price: "15" }],
            Name: "Kimchi (Shoyu, Tonkotsu, and Kimchi Broth)",
            Desc: ""
        },
        {
            id: 66,
            Category: "Ramen & Udon",
            Prices: [{ Size: "", Price: "15" }],
            Name: "Tempura Udon",
            Desc: ""
        },
        {
            id: 67,
            Category: "Sushi & Sashimi Entrees",
            Prices: [{ Size: "", Price: "24" }],
            Name: "Chirashi",
            Desc: "Assortment of 15 pc Sashimi on Black or White Rice"
        },
        {
            id: 68,
            Category: "Sushi & Sashimi Entrees",
            Prices: [{ Size: "", Price: "18" }],
            Name: "Unagi Don",
            Desc: "6pc Eel Sashimi on White Rice"
        },
        {
            id: 69,
            Category: "Sushi & Sashimi Entrees",
            Prices: [{ Size: "", Price: "22" }],
            Name: "Sushi Roll Combo",
            Desc: "Maguro, Hamachi, Shake, Ebi, Kani, Walu Nigiri and Cali Roll"
        },
        {
            id: 70,
            Category: "Sushi & Sashimi Entrees",
            Prices: [{ Size: "", Price: "24" }],
            Name: "Sushi and Sashimi Combo",
            Desc: "Chef's Choice of 5 Nigiri, 4 Sashimi"
        },
        {
            id: 71,
            Category: "Sushi & Sashimi Entrees",
            Prices: [{ Size: "", Price: "28" }],
            Name: "Sashimi Combo",
            Desc: "Maguro, Hamachi, Shake"
        },
        {
            id: 72,
            Category: "Sushi & Sashimi Entrees",
            Prices: [{ Size: "", Price: "70" }],
            Name: "Dinner for 2",
            Desc: "Chef's choice  of 10 Nigiri, 10 Sashimi, Spicy Tuna Roll, California Roll, Dragon Roll, Soup, Salad"
        },
        {
            id: 73,
            Category: "Sushi & Sashimi Entrees",
            Prices: [{ Size: "", Price: "130" }],
            Name: "ASA Combo",
            Desc: "Chef's Choice of 20 Nigiri, 20 Sashimi, Dragon, California, Spicy Tuna, and Salmon Roll"
        },
        {
            id: 74,
            Category: "Main Entrees (Comes with Miso and House Salad)",
            Prices: [{ Size: "", Price: "17" }],
            Name: "Chicken Teriyaki",
            Desc: ""
        },
        {
            id: 75,
            Category: "Main Entrees (Comes with Miso and House Salad)",
            Prices: [{ Size: "", Price: "19" }],
            Name: "Salmon Teriyaki",
            Desc: ""
        },
        {
            id: 76,
            Category: "Main Entrees (Comes with Miso and House Salad)",
            Prices: [{ Size: "", Price: "17" }],
            Name: "Beef Teriyaki",
            Desc: ""
        },
        {
            id: 77,
            Category: "Main Entrees (Comes with Miso and House Salad)",
            Prices: [{ Size: "", Price: "19" }],
            Name: "Korean Spicy Pork",
            Desc: ""
        },
        {
            id: 78,
            Category: "Main Entrees (Comes with Miso and House Salad)",
            Prices: [{ Size: "", Price: "17" }],
            Name: "Chicken Katsu",
            Desc: ""
        },
        {
            id: 79,
            Category: "Main Entrees (Comes with Miso and House Salad)",
            Prices: [{ Size: "", Price: "15" }],
            Name: "Yakisoba",
            Desc: "Choice of Chicken or Beef"
        },
        {
            id: 80,
            Category: "Main Entrees (Comes with Miso and House Salad)",
            Prices: [{ Size: "", Price: "17" }],
            Name: "Chef's Special Noodles",
            Desc: "Stir fried Noodles, Beef, Veggies"
        },
        {
            id: 81,
            Category: "Poke (Comes with Miso)",
            Prices: [{ Size: "", Price: "17" }],
            Name: "Tuna",
            Desc: "Black or White Rice, Seaweed Salad, Crab Mix, Cucumber, Tobiko, Poke Sauce"
        },
        {
            id: 82,
            Category: "Poke (Comes with Miso)",
            Prices: [{ Size: "", Price: "17" }],
            Name: "Salmon",
            Desc: "Black or White Rice, Seaweed Salad, Crab Mix, Cucumber, Tobiko, Poke Sauce"
        },
        {
            id: 83,
            Category: "Poke (Comes with Miso)",
            Prices: [{ Size: "", Price: "18" }],
            Name: "Combination",
            Desc: ""
        },
        {
            id: 84,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "7" }],
            Name: "California",
            Desc: ""
        },
        {
            id: 85,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Crab Crunch",
            Desc: ""
        },
        {
            id: 84,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Eel and Cucumber",
            Desc: ""
        },
        {
            id: 85,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Futomaki",
            Desc: ""
        },
        {
            id: 86,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "8" }],
            Name: "Philadelphia",
            Desc: ""
        },
        {
            id: 87,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Salmon Avocado",
            Desc: ""
        },
        {
            id: 88,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Salmon Skin",
            Desc: ""
        },
        {
            id: 89,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Shrimp Tempura",
            Desc: ""
        },
        {
            id: 90,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Salmon Roll",
            Desc: ""
        },
        {
            id: 91,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Tuna Roll",
            Desc: ""
        },
        {
            id: 92,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Yellowtail and Scallions",
            Desc: ""
        },
        {
            id: 93,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Spicy Salmon",
            Desc: ""
        },
        {
            id: 94,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Spicy Tuna",
            Desc: ""
        },
        {
            id: 95,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Spicy Yellowtail",
            Desc: ""
        },
        {
            id: 96,
            Category: "Beginner Rolls",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Spicy Shrimp",
            Desc: ""
        },
        {
            id: 97,
            Category: "Vegetarian Rolls",
            Prices: [{ Size: "", Price: "6" }],
            Name: "All Vegetable Roll (6 pc)",
            Desc: "Cucumber, Asparagus, Lettuce, Avocado"
        },
        {
            id: 98,
            Category: "Vegetarian Rolls",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Asparagus Roll (8pc)",
            Desc: ""
        },
        {
            id: 99,
            Category: "Vegetarian Rolls",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Avocado Roll (8 pc)",
            Desc: ""
        },
        {
            id: 100,
            Category: "Vegetarian Rolls",
            Prices: [{ Size: "", Price: "5" }],
            Name: "Kappa Maki (Cucumber Roll) (8 pc)",
            Desc: ""
        },
        {
            id: 101,
            Category: "Vegetarian Rolls",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Fiji Roll (8 pc)",
            Desc: "Cucumber, Avocado, Asparagus, Lettuce, Topped with Mango"
        },
        {
            id: 102,
            Category: "Vegetarian Rolls",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Four Seasons (8 pc)",
            Desc: "Tempura Asparagus, Cucumber, Shiso Leaf, Topped with Avocado and Garlic Sake Butter Sauce"
        },
        {
            id: 103,
            Category: "Vegetarian Rolls",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Green Giant (8 pc)",
            Desc: "Avocado, Cucumber, Asparagus, Topped with Seaweed Salad"
        },
        {
            id: 104,
            Category: "Vegetarian Rolls",
            Prices: [{ Size: "", Price: "6" }],
            Name: "Sweet Potato Roll (6 pc)",
            Desc: ""
        },
        {
            id: 105,
            Category: "Vegetarian Rolls",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Tofu Maddness (6 pc)",
            Desc: "Tempura Tofu and Asparagus, Avocado, Cucumber, Eel Sauce"
        },
        {
            id: 106,
            Category: "Vegetarian Rolls",
            Prices: [{ Size: "", Price: "10" }],
            Name: "Veggie Caterpillar Roll (8 pc)",
            Desc: "Cucumber, Asparagus, Lettuce, Topped with Avocado"
        },
        {
            id: 107,
            Category: "Vegetarian Rolls",
            Prices: [{ Size: "", Price: "7" }],
            Name: "Veggie Tempura Roll (6 pc)",
            Desc: "Broccoli, Sweet Potato, Asparagus"
        },
        {
            id: 108,
            Category: "Vegetarian Rolls",
            Prices: [{ Size: "", Price: "8" }],
            Name: "Vegan Cali Roll",
            Desc: "Tofu, Avocado, and Cucumber"
        },
        {
            id: 109,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Alaska Roll (8 pc)",
            Desc: "Baked salmon mix and cucumber topped with smoked salmon, avocado, crunch, eel sauce"
        },
        {
            id: 110,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "12" }],
            Name: "Amigo Roll (8 pc)",
            Desc: "Shrimp tempura and crab stick topped with avocado, shrimp, and eel sauce"
        },
        {
            id: 111,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "23" }],
            Name: "Asa Roll (10 pc)",
            Desc: "Whole tempura fried lobster tail, snow crab, cucumber, and asparagus topped with snow crab salad, drizzled with garlic sake butter sauce and eel sauce"
        },
        {
            id: 112,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Avalanche Roll (8 pc)",
            Desc: "Spicy crab and cucumber topped with white tuna and sweet aioli sauce"
        },
        {
            id: 113,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Bali Roll (8 pc)",
            Desc: "Spicy tuna and cucumber topped with seared albacore, avocado, tobiko, green onion, and poke sauce"
        },
        {
            id: 114,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "18" }],
            Name: "Black Dragon Roll (8 pc)",
            Desc: "Shrimp tempura, cream cheese, topped with smoked salmon, eel, eel sauce, and crunch"
        },
        {
            id: 115,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Bronco Roll (8 pc)",
            Desc: "Crab mix and cucumber topped with baked salmon, scallions, spicy aioli, and eel sauce"
        },
        {
            id: 116,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Caterpillar Roll (8 pc)",
            Desc: "Freshwater eel and cucumber topped with avocado and eel sauce"
        },
        {
            id: 117,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "12" }],
            Name: "Crab Lover Roll (8 pc)",
            Desc: "Crab stick and cucumber topped with spicy crab mix eel sauce, and crunch"
        },
        {
            id: 118,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Denver Roll (6 pc)",
            Desc: "Deep fried roll with cream cheese, salmon, tuna, and jalapeno topped with eel sauce, spicy mayo, and crunch"
        },
        {
            id: 119,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "16" }],
            Name: "Diablo Roll (8 pc)",
            Desc: "Spicy tuna and jalapeno topped with seared peppered tuna, habanero sauce, eel sauce, scallions, and wasabi tobiko"
        },
        {
            id: 120,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Dragon Roll (8 pc)",
            Desc: "Crab mix and cucumber topped with freshwater eel, avocado, and eel sauce"
        },
        {
            id: 121,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "12" }],
            Name: "DTC Roll (8 pc)",
            Desc: "Deep fried roll with spicy tuna, jalapeno, and cream cheese and topped with eel sauce, masago, and scallions"
        },
        {
            id: 122,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "12" }],
            Name: "Forbidden Roll (6 pc)",
            Desc: "Tuna, cream cheese, jalapeno, and black rice wrapped in soy paper with tobiko"
        },
        {
            id: 123,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Green Monster Roll (8 pc)",
            Desc: "Salmon and avocado topped with seaweed salad"
        },
        {
            id: 124,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Hawaiian Roll (6 pc)",
            Desc: "Tuna, salmon, yellowtail, crab stick, avocado wrapped in cucumber with ponzu sauce, daikon sprouts, and masago"
        },
        {
            id: 125,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Loco Roll (8 pc)",
            Desc: "Shimp tempura and cucumber topped with spicy tuna mix, mango salsa, scallions, and eel sauce"
        },
        {
            id: 126,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "17" }],
            Name: "Lover Roll (8 pc)",
            Desc: "Shrimp tempura, cream cheese, topped with salmon, avocado, eel sause, spicy mayo, and crunch"
        },
        {
            id: 127,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Manhattan Roll (8 pc)",
            Desc: "Spicy crab mix and cucumber topped with salmon, avocado, sweet mayo, eel sauce, and red tobiko"
        },
        {
            id: 128,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "13" }],
            Name: "Rainbow Roll (8 pc)",
            Desc: "Crab mix, avocado, and cucumber topped with salmon, tuna, white tuna shrimp, and avocado"
        },
        {
            id: 129,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "17" }],
            Name: "Rocky Mountain Roll (10 pc)",
            Desc: "Shrimp tempura, spicy tuna, cucumber, and lettuce topped with crab salad, eel sauce, and masago"
        },
        {
            id: 130,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "16" }],
            Name: "Salmon Fiesta (8 pc)",
            Desc: "Spicy salmon and cucumber topped with salmon and avocado"
        },
        {
            id: 131,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "16" }],
            Name: "Snow Beauty Roll (8 pc)",
            Desc: "White fish, shiso leaf, cucumber, and lettuce topped with white tuna and ponzu sauce"
        },
        {
            id: 132,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Spider Roll (6 pc)",
            Desc: "Tempura friend soft shell crab, crab mix, lettuce, asparagus, and cucumber with eel sauce"
        },
        {
            id: 133,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "16" }],
            Name: "Summer Roll (8 pc)",
            Desc: "Spicy yellowtail and cucumber topped with yellowtail, cilantro, jalapeno, shichimi, and ponzu sauce"
        },
        {
            id: 134,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "16" }],
            Name: "Sushi Burrito",
            Desc: "Spicy tuna mix, spicy crab mix, tamago, avocado, cilantro, red onion, and eel sauce"
        },
        {
            id: 135,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "14" }],
            Name: "Tokyo Roll (8 pc)",
            Desc: "Shrimp tempura and cucumber topped with spicy tuna, masago, scallions, with eel sauce"
        },
        {
            id: 136,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "20" }],
            Name: "U.B. Roll (10 pc)",
            Desc: "Whole tempura fried lobster tail and spicy crab mix topped with seared steak, garlic sake butter sauce, and eel sauce"
        },
        {
            id: 108,
            Category: "House Special Rolls",
            Prices: [{ Size: "", Price: "13" }],
            Name: "Volcano Roll (6 pc)",
            Desc: "Deep fried roll with crab mix, cream cheese, topped with spicy crab salad, tobiko, eel sauce"
        },
        {
            id: 147,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "Palazzo, Pinot Grigio",
            Desc: ""
        },
        {
            id: 148,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "Marchese Guadagni, Orvieto",
            Desc: ""
        },
        {
            id: 149,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "Kim Crawford, Sauvignon Blanc",
            Desc: ""
        },
        {
            id: 150,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "Cap Royal, Sauvignon Blanc",
            Desc: ""
        },
        {
            id: 151,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "Kendall Jackson, Chardonnay",
            Desc: ""
        },
        {
            id: 152,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "La Chertosa, Chardonnay",
            Desc: ""
        },
        {
            id: 153,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "Watermill, Viognier",
            Desc: ""
        },
        {
            id: 154,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "House White",
            Desc: ""
        },
        {
            id: 155,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "Black Diamond, Rosé",
            Desc: ""
        },
        {
            id: 156,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "Plum Wine",
            Desc: ""
        },
        {
            id: 157,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "La Famiglia, Pinot Noir",
            Desc: ""
        },
        {
            id: 158,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "Marchese Guadagni, Chianti",
            Desc: ""
        },
        {
            id: 159,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "Black Diamond, Merlot",
            Desc: ""
        },
        {
            id: 160,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "The Jack, Cabernet Sauvignon",
            Desc: ""
        },
        {
            id: 161,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "La Chertosa, Red Zinfandel",
            Desc: ""
        },
        {
            id: 162,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "Watermill, Cabernet Sauvignon",
            Desc: ""
        },
        {
            id: 163,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "Mi Sueño, Red Blend",
            Desc: ""
        },
        {
            id: 164,
            Category: "Wine",
            Prices: [{ Size: "", Price: "" }],
            Name: "House Red",
            Desc: ""
        },
        {
            id: 165,
            Category: "Beer",
            Prices: [{ Size: "", Price: "" }],
            Name: "Kirin",
            Desc: ""
        },
        {
            id: 166,
            Category: "Beer",
            Prices: [{ Size: "", Price: "" }],
            Name: "Sapporo",
            Desc: ""
        },
        {
            id: 167,
            Category: "Beer",
            Prices: [{ Size: "", Price: "" }],
            Name: "Asahi",
            Desc: ""
        },
        {
            id: 168,
            Category: "Beer",
            Prices: [{ Size: "", Price: "" }],
            Name: "Corona",
            Desc: ""
        },
        {
            id: 169,
            Category: "Beer",
            Prices: [{ Size: "", Price: "" }],
            Name: "Blue Moon",
            Desc: ""
        },
        {
            id: 170,
            Category: "Beer",
            Prices: [{ Size: "", Price: "" }],
            Name: "Coors Light",
            Desc: ""
        },
        {
            id: 171,
            Category: "Spirits",
            Prices: [{ Size: "", Price: "" }],
            Name: "Jack Daniel's",
            Desc: ""
        },
        {
            id: 172,
            Category: "Spirits",
            Prices: [{ Size: "", Price: "" }],
            Name: "Johnnie Walker",
            Desc: ""
        },
        {
            id: 173,
            Category: "Spirits",
            Prices: [{ Size: "", Price: "" }],
            Name: "Hennessy Cognac",
            Desc: ""
        },
        {
            id: 174,
            Category: "Spirits",
            Prices: [{ Size: "", Price: "" }],
            Name: "Jim Beam",
            Desc: ""
        },
        {
            id: 175,
            Category: "Spirits",
            Prices: [{ Size: "", Price: "" }],
            Name: "Maker's Mark",
            Desc: ""
        },
        {
            id: 176,
            Category: "Spirits",
            Prices: [{ Size: "", Price: "" }],
            Name: "Togouchi",
            Desc: ""
        },
        {
            id: 177,
            Category: "Spirits",
            Prices: [{ Size: "", Price: "" }],
            Name: "Yamato",
            Desc: ""
        },
        {
            id: 178,
            Category: "Spirits",
            Prices: [{ Size: "", Price: "" }],
            Name: "Grey Goose",
            Desc: ""
        },
        {
            id: 179,
            Category: "Spirits",
            Prices: [{ Size: "", Price: "" }],
            Name: "Tito's",
            Desc: ""
        },
        {
            id: 180,
            Category: "Spirits",
            Prices: [{ Size: "", Price: "" }],
            Name: "Stolichnaya",
            Desc: ""
        },
        {
            id: 181,
            Category: "Spirits",
            Prices: [{ Size: "", Price: "" }],
            Name: "Ciroc",
            Desc: ""
        },
        {
            id: 182,
            Category: "Spirits",
            Prices: [{ Size: "", Price: "" }],
            Name: "Jose Cuervo",
            Desc: ""
        },
        {
            id: 183,
            Category: "Spirits",
            Prices: [{ Size: "", Price: "" }],
            Name: "Patron",
            Desc: ""
        },
        {
            id: 184,
            Category: "Spirits",
            Prices: [{ Size: "", Price: "" }],
            Name: "Bombay Sapphire",
            Desc: ""
        },
        {
            id: 185,
            Category: "Cocktails",
            Prices: [{ Size: "", Price: "" }],
            Name: "Cosmopolitan",
            Desc: ""
        },
        {
            id: 186,
            Category: "Cocktails",
            Prices: [{ Size: "", Price: "" }],
            Name: "Vodka Martini",
            Desc: ""
        },
        {
            id: 187,
            Category: "Cocktails",
            Prices: [{ Size: "", Price: "" }],
            Name: "Sake Martini",
            Desc: ""
        },
        {
            id: 188,
            Category: "Cocktails",
            Prices: [{ Size: "", Price: "" }],
            Name: "Long Island",
            Desc: ""
        },
        {
            id: 189,
            Category: "Cocktails",
            Prices: [{ Size: "", Price: "" }],
            Name: "Lemon Drop",
            Desc: ""
        },
        {
            id: 190,
            Category: "Cocktails",
            Prices: [{ Size: "", Price: "" }],
            Name: "Margarita",
            Desc: ""
        },
        {
            id: 191,
            Category: "Cocktails",
            Prices: [{ Size: "", Price: "" }],
            Name: "Vodka Cranberry",
            Desc: ""
        },
        {
            id: 192,
            Category: "Cocktails",
            Prices: [{ Size: "", Price: "" }],
            Name: "Gin and Tonic",
            Desc: ""
        },
        {
            id: 193,
            Category: "Cocktails",
            Prices: [{ Size: "", Price: "" }],
            Name: "Jack and Coke",
            Desc: ""
        },
        {
            id: 194,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Otokoyama",
            Desc: ""
        },
        {
            id: 195,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Hakushika Chokara",
            Desc: ""
        },
        {
            id: 196,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Junmai Yaegaki",
            Desc: ""
        },
        {
            id: 197,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Junmai Draft Yaegaki",
            Desc: ""
        },
        {
            id: 198,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Kurosawa",
            Desc: ""
        },
        {
            id: 199,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Kuromatsu Junmai Daiginjo",
            Desc: ""
        },
        {
            id: 200,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Hakushika Fresh and Light",
            Desc: ""
        },
        {
            id: 201,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Yaegaki Nigori Sake",
            Desc: ""
        },
        {
            id: 202,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Snow Beauty",
            Desc: ""
        },
        {
            id: 203,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Hakushika Japanese Orchard",
            Desc: "white peach, citrus yuzu, or pineapple flavors"
        },
        {
            id: 204,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Hana Kohaku Plum Sake",
            Desc: ""
        },
        {
            id: 205,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "House Sake",
            Desc: "small or large"
        },
        {
            id: 206,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Purple Haze",
            Desc: "raspberry liquor; small or large"
        },
        {
            id: 207,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Apple Sake",
            Desc: "green apple liquor; small or large"
        },
        {
            id: 208,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Golden Haze",
            Desc: "citrus liquor; small or large"
        },
        {
            id: 209,
            Category: "Sake",
            Prices: [{ Size: "", Price: "" }],
            Name: "Plum Sake",
            Desc: "plum wine; small or large"
        },
        {
            id: 210,
            Category: "Non-Alcoholic Drinks",
            Prices: [{ Size: "", Price: "" }],
            Name: "Arnold Palmer",
            Desc: ""
        },
        {
            id: 211,
            Category: "Non-Alcoholic Drinks",
            Prices: [{ Size: "", Price: "" }],
            Name: "Shirley Temple",
            Desc: ""
        },
        {
            id: 212,
            Category: "Non-Alcoholic Drinks",
            Prices: [{ Size: "", Price: "" }],
            Name: "Ramune",
            Desc: "(Orange/Strawberry/Original)"
        },
        {
            id: 213,
            Category: "Non-Alcoholic Drinks",
            Prices: [{ Size: "", Price: "" }],
            Name: "Juice",
            Desc: "(Orange/Apple/Cranberry)"
        },
        {
            id: 214,
            Category: "Non-Alcoholic Drinks",
            Prices: [{ Size: "", Price: "" }],
            Name: "Fountain Drinks",
            Desc: "pepsi, diet pepsi, lemonade, sprite, dr.pepper"
        },
        {
            id: 215,
            Category: "Non-Alcoholic Drinks",
            Prices: [{ Size: "", Price: "" }],
            Name: "Iced Tea",
            Desc: "unsweetened"
        },
        {
            id: 216,
            Category: "Non-Alcoholic Drinks",
            Prices: [{ Size: "", Price: "" }],
            Name: "Hot Tea",
            Desc: "(Green/Black/Jasmine)"
        },
        {
            id: 217,
            Category: "Non-Alcoholic Drinks",
            Prices: [{ Size: "", Price: "" }],
            Name: "Soda Water",
            Desc: ""
        },
        {
            id: 218,
            Category: "Non-Alcoholic Drinks",
            Prices: [{ Size: "", Price: "" }],
            Name: "Fiji Water",
            Desc: ""
        },
        {
            id: 219,
            Category: "Non-Alcoholic Drinks",
            Prices: [{ Size: "", Price: "" }],
            Name: "San Pellegrino",
            Desc: ""
        },
        {
            id: 220,
            Category: "Non-Alcoholic Drinks",
            Prices: [{ Size: "", Price: "" }],
            Name: "Red Bull",
            Desc: ""
        },
    ]
    const [categories, setCategories] = useState([])
    const [chosenCat, setChosenCat] = useState("")

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "Menu", Views: 0 })
        var tempCategs = []
        for (var i in menu) {
            tempCategs.push(menu[i].Category)
        }
        const temp = [...new Set(tempCategs)]
        setCategories(temp)
    }, [])
    return (
        <div className='main'>
           {c_helmet("Menu")}
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="padding font1">
                <h1 className='page-title color4'>Our Menu</h1>
                <br />
                <div className='menu'>
                    <div className="menu-left">
                        <img src={img1} className='menu-img' />
                    </div>
                    <div className='menu-right'>
                        {
                            categories.map((cat, i) => {
                                return (
                                    <div key={i} className="">
                                        <h1 id={cat} className={`menu-cat bg1 color2 font1 ${cat == "Vegetarian Rolls" ? "green" : ""}`}>{cat}</h1>
                                        <div className='menu-wrap'>
                                            {
                                                menu.map((item, j) => {
                                                    if (item.Category == cat) {
                                                        return (
                                                            <div id={item.id} key={j} className='menu-item font2'>
                                                                <div className='menu-item-top'>
                                                                    <div>
                                                                        <h2 className='menu-item-name'>{item.Name}</h2>
                                                                        <p className='menu-item-desc'>{item.Desc}</p>
                                                                    </div>
                                                                    <div className='menu-item-prices'>
                                                                        {
                                                                            item.Prices.map((price, p) => {
                                                                                return (
                                                                                    <div key={p} className='menu-item-price-block'>
                                                                                        <h2 className='menu-item-price font1'>{price.Price}</h2>
                                                                                        <h2 className='menu-item-size'>{price.Size}</h2>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
