import React, { useEffect } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import '../STYLESHEETS/Locations.css'
import logo from '../PHOTOS/stock.png'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { Link } from 'react-router-dom'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { c_helmet, c_mainURL } from '../Constants'
import { Helmet } from 'react-helmet'
// 

export default function History() {
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "Locations", Views: 0 })
    }, [])
    return (
        <div className='main'>
            {c_helmet("Locations")}
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="locations font1">
                <h1 className='page-title color4'>Locations & Hours</h1>
                <br/>
                <div className='location-pair'>
                    <div className='location-pair-split'>
                        <div>
                            <h1><span className='color4'>ASA SUSHI</span> | Greenwood Village</h1>
                            <h2>5302 Dtc Boulevard #100<br />
                                Greenwood Village, CO 80111<br />
                                USA</h2>

                        </div>
                        <div>
                            <h3 className='color4'>Hours:</h3>
                            <ul className='font2'>
                                <li><b>Mon - Thu:</b><br/> 11:00 AM – 2:30 PM, <br/>4:00 – 9:00 PM</li>
                                {/* <li>Tuesday: 11:00 AM – 2:30 PM, <br/>4:00 – 9:00 PM</li>
                                <li>Wednesday: 11:00 AM – 2:30 PM, <br/>4:00 – 9:00 PM</li>
                                <li>Thursday: 11:00 AM – 2:30 PM, <br/>4:00 – 9:00 PM</li> */}
                                <li><b>Friday:</b><br/> 11:00 AM – 2:30 PM, <br/>4:00 – 9:30 PM</li>
                                <li><b>Saturday:</b><br/> 11:00 AM – 2:30 PM, <br/>4:00 – 9:30 PM</li>
                                <li><b>Sunday:</b><br/> 4:00 – 8:30 PM</li>
                            </ul>
                        </div>
                    </div>
                    <div className='locations-wrap'>
                        <div className='locations-map'>
                        <iframe className="map" id="gmap_canvas" src="https://maps.google.com/maps?q=Asa%20Sushi%20Greenwood&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
