import React, { useEffect } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import '../STYLESHEETS/Reviews.css'
import logo from '../PHOTOS/stock.png'
import img1 from '../PHOTOS/shop.jpg'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { Link } from 'react-router-dom'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { Helmet } from 'react-helmet'
import { c_helmet, c_mainURL } from '../Constants'

export default function Reviews() {
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }



    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "Reviews", Views: 0 })
    }, [])
    return (
        <div className='main'>
            {c_helmet("Reviews")}
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="padding font1">
                <h1 className='page-title'>See what <span className='color4'>others are saying</span>.</h1>
                <br />
                <div className='reviews'>
                    <div className='review border3'>
                        <h1>"If you like sushi/fish, this is a great place for you. Lots of options with a cozy atmosphere and great service. Definitely worth a try if you are curious."</h1>
                        <br />
                        <h2>William Valentini</h2>
                        <p className='color3'>Local Reviewer</p>
                    </div>
                    <div className='review border3'>
                        <h1>"This is my #1 sushi place to go to! Everyone who works there is so friendly and you can really appreciate the attention to detail that goes into their dishes. The quality of the fish is always perfect! If I could give 10 stars, I would!"</h1>
                        <br />
                        <h2>Kelsey Lewis</h2>
                        <p className='color3'>Local Reviewer</p>
                    </div>
                    <div className='review border3'>
                        <h1>"This place has the best food and the best service and the best cooks because as long as I don't have to do the dishes it's great right no just kidding they are really great place we eat there almost once a week go here you won't be sorry."</h1>
                        <br />
                        <h2>Trisha Trixie Hunter-Merrill</h2>
                        <p className='color3'>Local Reviewer</p>
                    </div>
                    <div className='review border3'>
                        <h1>"Excellent service from waiters/chef. Great options on their menu (I don’t eat bottom dwelling creations) and great prices. I wish they served IPAs (specifically Colorado based beers) but hey, what sushi place in Colorado does?! They have very clean bathrooms too!"</h1>
                        <br />
                        <h2>Keith Mathews</h2>
                        <p className='color3'>Local Reviewer</p>
                    </div>
                    <div className='review border3'>
                        <h1>"I can live off the monkey brains here. I love the great customer service and quality of sushi."</h1>
                        <br />
                        <h2>Kevin Freire</h2>
                        <p className='color3'>Local Reviewer</p>
                    </div>
                    <div className='review border3'>
                        <h1>"I've only been here for lunch, but this place is great. Amazing staff and atmosphere. The have wonderful lunch deals and the food is fantastic. In my opinion, the best sushi place in the area."</h1>
                        <br />
                        <h2>Josh Steichen</h2>
                        <p className='color3'>Local Reviewer</p>
                    </div>
                    <div className='review border3'>
                        <h1>"I ordered a few rolls and some other items through Uber Eats and the food arrived super quickly and super fresh! The rolls were perfect and everything looked and tasted amazing! Definitely will be ordering from here more often."</h1>
                        <br />
                        <h2>Pierce Wilfong</h2>
                        <p className='color3'>Local Reviewer</p>
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
