import React, { useEffect, useState } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import '../STYLESHEETS/Blog.css'
import logo from '../PHOTOS/stock.png'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { Link, useNavigate } from 'react-router-dom'
import blog1img1 from '../PHOTOS/MAIN/sushi27.jpg'
import blog1img2 from '../PHOTOS/MAIN/sushi26.jpg'
import blog3img1 from '../PHOTOS/MAIN/sushi25.jpg'
import blog3img2 from '../PHOTOS/MAIN/sushi34.jpg'

import blog2img1 from '../PHOTOS/MAIN/sushi28.jpg'
// 
import { setBlogPostState } from '../REDUX/SLICES/BlogPostSlice'
import { useDispatch } from 'react-redux'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { Helmet } from 'react-helmet'
import { c_helmet, c_mainURL } from '../Constants'

export default function Blog() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const testBlogs = [
        {
            id: 0,
            Date: "May 5, 2023",
            Title: "The Art of Catching and Preparing Fresh Sushi",
            Desc: "Sushi is a beloved dish around the world, and it's no wonder why. This Japanese cuisine offers a unique combination of flavors and textures that leave diners wanting more. While it's easy to go to a sushi restaurant and order a roll, have you ever wondered how fresh sushi is caught? In this blog post, we'll explore the process of catching fresh sushi and how it makes its way to your plate.",
            ImgPath: blog1img1,
            Author: "",
            Tags: "Sushi, Asa Sushi, Fresh Fish", HTML: <div className='blogpost-wrap font2'>
                <p>
                    Sushi is a beloved dish around the world, and it's no wonder why. This Japanese cuisine offers a unique combination of flavors and textures that leave diners wanting more. While it's easy to go to a sushi restaurant and order a roll, have you ever wondered how fresh sushi is caught? In this blog post, we'll explore the process of catching fresh sushi and how it makes its way to your plate.
                </p>
                <img src={blog1img1} className="blogpost-img" alt='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
                <p>The first step in catching fresh sushi is to head out to sea. Sushi is typically made from raw fish, so fishermen must catch the freshest fish possible. They use a variety of methods, including nets and lines, to catch the fish. Some fishermen even use harpoons to catch larger fish like tuna.</p>
                <p>
                    Once the fish is caught, it's immediately cleaned and gutted on the boat. This is an important step because it ensures the fish is as fresh as possible. Any bacteria or parasites that could spoil the fish are removed during the cleaning process. The fish is then placed in a refrigerated storage area on the boat to keep it fresh.
                </p>
                <br />
                <p>
                    When the boat returns to shore, the fish is transported to a processing facility. At the facility, the fish is again inspected for quality and freshness. The fish is then cut into fillets and packaged for transportation to sushi restaurants around the world.
                </p>
                <br />
                <p>
                    When the fresh sushi arrives at the restaurant, it's inspected again to ensure it meets the restaurant's standards. The sushi chef will then use the fish to create a variety of sushi rolls and nigiri. The chef will slice the fish into thin strips and place them on top of rice or wrap them in seaweed to create rolls.
                </p>
                <img src={blog1img2} className="blogpost-img" alt='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
                <h3>
                    When you take a bite of a delicious sushi roll, you're enjoying the culmination of a process that started at sea. By understanding the journey of the fish from the ocean to your plate, you can gain a new appreciation for the skill and dedication that goes into creating this beloved cuisine. So, the next time you savor a piece of fresh sushi, take a moment to remember the passion and effort that went into catching and preparing it.
                </h3>
            </div>
        },
        {
            id: 1,
            Date: "May 5, 2023",
            Title: "Introducing the New Asa Sushi Website: Dine with us today!",
            Desc: "We are excited to announce the launch of our new website for Asa Sushi, where you can now browse our menu, make online reservations, and learn more about our restaurant.",
            ImgPath: blog2img1,
            Author: "",
            Tags: "Sushi, Asa Sushi Website, Restaurant", HTML: <div className='blogpost-wrap font2'>
                <h3>We are excited to announce the launch of our new website for Asa Sushi, where you can now browse our menu, make online reservations, and learn more about our restaurant.</h3>
                <p>
                    Asa Sushi has been a staple in the local community for years, serving up the freshest sushi and Japanese cuisine. With the launch of our new website, we're making it even easier for our customers to enjoy our delicious food.
                </p>
                <img src={blog2img1} className="blogpost-img" alt='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
                <p>Our website features a user-friendly interface, making it simple to navigate and explore our menu. You can now easily browse our selection of sushi rolls, sashimi, and other Japanese dishes, and even order online for pickup or delivery. We've also included a detailed list of our ingredients, so you can be confident in the quality of our food.</p>
                <p>
                    In addition to our online ordering, our website allows you to make reservations for our restaurant. Whether you're planning a night out with friends or a special occasion, our online booking system makes it easy to reserve a table in just a few clicks.
                </p>
                <br />
                <p>
                    Asa Sushi is more than just a restaurant; it's an experience. We've designed our new website to reflect our commitment to excellence and our passion for serving the best sushi in town. From the moment you visit our site, you'll be immersed in the world of Asa Sushi.
                </p>
                <br />
                <h3>
                    We invite you to visit our new website and explore all that Asa Sushi has to offer. We're confident that you'll love our new site and our delicious food. Thank you for your continued support, and we look forward to serving you soon!
                </h3>
            </div>
        },
        {
            id: 2,
            Date: "November 5, 2023",
            Title: "Raving Reviews: Asa Sushi - A Culinary Delight",
            Desc: "Discover what our valued customers are saying about their unforgettable dining experiences at Asa Sushi.",
            ImgPath: blog3img1,
            Author: "",
            Tags: "Sushi, Asa Sushi Reviews, Restaurant",
            HTML: <div className='blogpost-wrap font2'>
                <h3>Discover the Culinary Delight at Asa Sushi</h3>
                <p>
                    At Asa Sushi, we take pride in delivering exceptional culinary experiences, and our customers can't stop raving about it. Here's a glimpse of the great reviews we've received:
                </p>
                <img src={blog3img2} className="blogpost-img" alt='Asa Sushi Reviews' />
                <h4> "A Sushi Lover's Paradise"</h4>
                <p>As a sushi enthusiast, I've dined at many sushi restaurants, but Asa Sushi stands out. The quality and freshness of their sushi are unparalleled. It's a sushi lover's paradise, and I can't recommend it enough.</p>
                <h4> "Impeccable Service"</h4>
                <p>The staff at Asa Sushi provides impeccable service. They go above and beyond to make your dining experience exceptional. You're not just a customer; you're a valued guest, and that's what keeps me coming back.</p>
                <h4> "Memorable Dining Moments"</h4>
                <p>I've celebrated special occasions at Asa Sushi, and each visit has been a memorable experience. The ambiance, the food, and the service combine to create unforgettable dining moments.</p>
                <h4> "An Unbeatable Menu"</h4>
                <p>Asa Sushi offers an unbeatable menu. From classic sushi rolls to innovative creations, there's something for everyone. I've explored their menu extensively, and I'm yet to be disappointed.</p>
                <br />
                <p>
                    We're truly humbled by the kind words and praise from our valued customers. It's your support and feedback that drive us to continue delivering exceptional sushi and dining experiences. Thank you for being a part of the Asa Sushi journey, and we look forward to creating more unforgettable moments with you.
                </p>
            </div>
        },
        
    ]
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }
    const [firstBlog, setFirstBlog] = useState({})
    const [tempBlogs, setTempBlogs] = useState([])

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        testBlogs.reverse()
        setFirstBlog(testBlogs[0])
        setTempBlogs(testBlogs)
        firebaseGetPageViews({ Name: "Blog", Views: 0 })
    }, [])
    return (
        <div className='main'>
            {c_helmet("Blog")}
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="blog font1">
                <h1 className='page-title'>Blog</h1>
                <div className='blog-wrap'>
                    <div className='blog-left'>
                        <div className='first-blog-block' onClick={() => {
                            dispatch(setBlogPostState(firstBlog));
                            navigate('/blogpost')
                        }}>
                            <img src={firstBlog.ImgPath} className="first-blog-img" />
                            <div className='first-blog-text'>
                                <p className='first-blog-date'>{firstBlog.Date}</p>
                                <h1 className='first-blog-title color4'>{firstBlog.Title}</h1>
                                <p className='first-blog-desc font2'>{firstBlog.Desc}</p>
                                <div className='first-blog-tags'>
                                    {/* {
                                        firstBlog.Tags.length > 0 ?
                                            firstBlog.Tags.split(",").map((tag, j) => {
                                                return (
                                                    <p key={j} className='first-blog-tag border2'>{tag}</p>
                                                )
                                            }) : <div></div>
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='blog-right'>
                        {
                            tempBlogs.map((blog, i) => {
                                if (i > 0) {
                                    return (
                                        <div key={i} className='blog-block' onClick={() => {
                                            dispatch(setBlogPostState(blog));
                                            navigate('/blogpost')
                                        }}>
                                            <img src={blog.ImgPath} className="blog-img" />
                                            <div className='blog-text'>
                                                <p className='blog-date'>{blog.Date}</p>
                                                <h1 className='blog-title color4'>{blog.Title}</h1>
                                                <p className='blog-desc font2'>{blog.Desc}</p>
                                                <div className='blog-tags'>
                                                    {
                                                        blog.Tags.split(",").map((tag, j) => {
                                                            return (
                                                                <p key={j} className='blog-tag border2'>{tag}</p>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
