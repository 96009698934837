import { Helmet } from "react-helmet"

export const c_businessName = "Asa Sushi"
export const c_businessDesc = "Discover the authentic taste of Japan at Asa Sushi. Our skilled chefs use only the freshest ingredients to craft mouth-watering sushi rolls, sashimi, and other Japanese delicacies. With an extensive menu that includes classic and creative options, there's something for everyone, including vegetarian and gluten-free options. Our cozy and modern ambiance, attentive service, and delicious food make Asa Sushi the perfect spot for a memorable meal with family and friends. Book your table today and enjoy the best sushi in town!"
export const c_instagram = "https://www.instagram.com/asa.sushi/?hl=en"
export const c_facebook = "https://www.facebook.com/asamgl/"
export const c_yelp = "https://www.yelp.com/biz/asa-sushi-greenwood-village-2"
export const c_twitter = ""
export const c_logoName = "stock.png"
export const c_mainURL = "https://asasushibgl.web.app"
// 
export const firebase_configObj = {
  apiKey: "AIzaSyDHw9fvrQj5RkqMKQpCrJaqSNUgE2bftAA",
  authDomain: "asasushimgl.firebaseapp.com",
  projectId: "asasushimgl",
  storageBucket: "asasushimgl.appspot.com",
  messagingSenderId: "39418675483",
  appId: "1:39418675483:web:a173111b66b4be3bc9d3be",
  measurementId: "G-RE46R5797T"
}

// 
export const c_helmet = (page) => {
  const desc = "Indulge in a delectable sushi experience at Asa Sushi, where authentic flavors and exquisite craftsmanship harmonize. Discover our diverse menu of fresh, handcrafted sushi rolls, sashimi, and nigiri, expertly prepared by our skilled chefs. Immerse yourself in the artistry of Japanese cuisine and savor every bite at Asa Sushi, your premier destination for exceptional sushi dining."
  return (
    <Helmet>
      <title>{page} | Greenwood Village, Colorado | Asa Sushi Restaurant</title>
      <meta name="description" content={`${desc}`} />
      <meta name="keywords" content="sushi, sushi restaurant, Greenwood Village, Colorado, sushi rolls" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={`${c_mainURL}`} />
      <meta property="og:title" content={`${page} | Greenwood Village, Colorado | Asa Sushi Restaurant`} />
      <meta property="og:description" content={`${desc}`} />
      <meta property="og:url" content={`${c_mainURL}`} />
      <meta property="og:image" content={`${c_mainURL}/src/PHOTOS/stock.png`} />
    </Helmet>
  )
}
// 
export const emailjs_publicKey = "eaOYb8X6nqSrLTHBS"
export const emailjs_serviceID = "service_s8a6uls"
export const emailjs_fromEmail = "asasushi.inbox@gmail.com"
// 
export const emailjs_schedule_templateID = "template_65xnt8b"
export const emailjs_mySchedule_templateID = "template_3k7viy5"
export const emailjs_schedule_message = `Thank you for scheduling an appointment with ${c_businessName}. Please make sure to arrive 10 minutes before your confirmed time to not lose your place in line. Otherwise, you can schedule another time for a different date.`
// 
export const emailjs_contact_templateID = "template_6ez9gil"
export const emailjs_myContact_templateID = "template_6v4x43c"
export const emailjs_contact_message = `Your inquiry has been successfully received. A follow up email will be sent shortly.`
// 
export const emailjs_quotes_templateID = "template_b3g78oj"
export const emailjs_myQuotes_templateID = "template_m433xgd"
//
export const emailjs_shop_templateID = "template_xkveshb"
export const emailjs_myShop_templateID = "template_whvwdyo"
// 
export const emailjs_order_templateID = "template_tu3ugds"
// 
export const square_appID = "sandbox-sq0idb-en5EWhNy5BmN4JbNHzXBsw"
export const square_locationID = "LVJYVW6F0ECCC"
export const square_accessToken = "EAAAEBhC-J6P2ZMn9VbKW5vC6vHV-DrgUzb2kiCdQ_lics1OVNNWkfoD473D86iE"

export const stripe_publishableKey = "pk_test_51MajXbKx2glJRMlwXbLVRs6jolz58bvODFBxDGs3QycWHealravrW7E9mszvrw4N85DviRctPEEwGtnFTmlQCTTs00aR6DMzzS"
export const stripe_secretKey = "sk_test_51MajXbKx2glJRMlw9H0xIW3l5STyygi6EuK6oIR7FgO5AnM37cxV4Ve24akw4c4cefORoIzdc48ZoCEVo2cuA6sa00lLrKUEix"
export const stripe_apiURL = "https://us-central1-happy-code-templates.cloudfunctions.net"
export const stripe_redirectSuccess = "https://happy-code-templates.web.app/success"
export const stripe_redirectFailure = "https://happy-code-templates.web.app/failure"